<div class="reporter-login"
     reporterLoginImage>
</div>


<reporter-modal [show]="!hideView"
                modalClasses="login-modal"
                [displayCloseBtn]="false"
                [noFooter]="true">
  <div role="modal-body">
    <reporter-custom-ui-config-login *ngIf="loginPageVersion === 'CUSTOM_UI_CONFIG'"
                                     [uiConfig]="uiConfig"
                                     [isAuthenticated]="isAuthenticated"
                                     [sso401]="sso401"
                                     [currentUser]="currentUser"
                                     [gbru]="gbru"
                                     (login)="oidcLogin()"
                                     (submit)="onSubmit()"
                                     [failed]="failed"
                                      [customerMessageError]="customerMessageError"
                                      [loginForm]="loginForm">
    </reporter-custom-ui-config-login>
    <ng-container *ngIf="loginPageVersion === 'DEFAULT'">
      <div class="container text-center">
        <h3>Welcome!</h3>
        <div *ngIf="gbru && !sso401">
          Your session has expired, please login again to continue using the application.
        </div>
        <div *ngIf="!gbru && !sso401">
          ClaimZone Reporter is now GBGO® firstconnect.
          For your convenience, please bookmark this page.
        </div>
        <div *ngIf="sso401">
          <p class="font-weight-bold">
        <span>A problem occurred verifying the user account. Please contact
        <a href="mailto:support@mvsc.com">support&#64;mvsc.com</a> for
          assistance.</span>
          </p>
        </div>
      </div>
      <div [hidden]="useLegacy"
           class="container text-center"
           role="modal-body">
        <button class="btn btn-action sso mt-4"
                (click)="oidcLogin()">LOGIN USING SINGLE SIGN ON
        </button>
        <div *ngIf="loginType !== 'SSO_ONLY'"
             class="alternative legacy mt-3">
          <button class="btn btn-link px-0"
                  (click)="useLegacy = true">Click here
          </button>
          if you do not have Single Sign On
        </div>
      </div>
      <div [hidden]="!useLegacy"
           class="container text-center mt-3"
           role="modal-body">
        <form *ngIf="loginForm !== undefined"
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()"
              autocomplete="off">
          <div class="form-group">
            <label class="required">Username</label>
            <input type="text"
                   class="form-control required"
                   formControlName="userId"
                   placeholder="john.smith">
          </div>
          <div class="form-group mb-1">
            <label class="required">Password</label>
            <input type="password"
                   class="form-control required"
                   formControlName="password"
                   placeholder="Password"
                   autocomplete="new-password">
          </div>
          <div *ngIf="failed"
               class="text-danger my-3">
            {{customerMessageError !== '' ? customerMessageError : 'Invalid credentials.'}}
          </div>
          <div class="form-group">
            <button type="submit"
                    class="btn btn-action px-5 mt-4"
                    [disabled]="loginForm.invalid">Login
            </button>
          </div>
        </form>
        <div *ngIf="loginType !== 'LEGACY_ONLY'"
             class="alternative sso mt-3">
          <button class="btn btn-link px-0"
                  (click)="useLegacy = false">
            Click here
          </button>
          if you want to use Single Sign On
        </div>
      </div>
    </ng-container>
  </div>
</reporter-modal>
